import React from 'react';
import './App.css';
import { useParams } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';

class YearList extends React.Component {
    render() {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear; year >= 2012; year--) {
            years.push(<option key={year} value={year}>{year}</option>)
        }
        return (years);
    }    
}

class Mortgage extends React.Component {

    constructor(props) {
        super(props);
        let currentTime = new Date();

        let year = currentTime.getFullYear();
        if (props.params.year) {
           let paramYear = parseInt(props.params.year);
           if ((paramYear >= 2012) && (paramYear <= year)) {
                year = paramYear;
           }
        }

        let month = currentTime.getMonth() + 1;
        if (props.params.month) {
           let paramMonth = parseInt(props.params.month);
           if ((paramMonth >= 1) && (paramMonth <= 12)) {
                month = paramMonth;
           }
        }

        let downpayment = 0;
        if (props.params.downpayment) {
            let paramDownpayment = parseInt(props.params.downpayment);
            if ((paramDownpayment >= 0) && (paramDownpayment <= 50000000)) {
                downpayment = paramDownpayment;
            }
        }

        let monthly = 0;
        if (props.params.monthly) {
            let parammonthly = parseInt(props.params.monthly);
            if ((parammonthly >= 0) && (parammonthly <= 50000000)) {
                monthly = parammonthly;
            }
        }

        this.setDownpayment = this.setDownpayment.bind(this);
        this.setMonthlyPayment = this.setMonthlyPayment.bind(this);
        this.handleYear = this.handleYear.bind(this);
        this.handleMonth = this.handleMonth.bind(this);

        this.state = {
            downpayment: downpayment,
            monthlyPayment: monthly,
            totalPayment: 0,
            startYear: year,
            startMonth: month,
            initialBitcoin: 0,
            monthlyBitcoin: 0,
            bitcoinPrice: 0,
            shareText: "Share the Results!"
        };
    }

    componentDidMount() {
        fetch("https://prices.bitcoinmortgage.info/prices.json")
        .then(res => res.json())
    .then(
        (result) => {
            this.setState({
            prices: result.prices,
            bitcoinPrice: parseInt(result.current_price.usd),
            totalPayment: this.getTotalPayment(
                this.state.downpayment,
                this.state.monthlyPayment,
                this.state.startYear,
                this.state.startMonth
            ),
            initialBitcoin: this.getInitialBitcoin(
                this.state.downpayment,
                this.state.startYear,
                this.state.startMonth
            ),
            monthlyBitcoin: this.getMonthlyBitcoin(
                this.state.monthlyPayment,
                this.state.startYear,
                this.state.startMonth
            )
            });
        },
        (error) => {
            this.setState({
            error
            });
        })
    }
    

    getCurrentYearMonth() {
        let currentTime = new Date();
        let year = currentTime.getFullYear()
        let month = currentTime.getMonth() + 1;
        let yearMonth = year * 12 + month;
        return yearMonth;
    }

    getTotalPayment(downpayment, monthlyPayment, startYear, startMonth) {
        let yearMonth = this.getCurrentYearMonth();
        let startYearMonth = startYear * 12 + startMonth;
        let difference = yearMonth - startYearMonth;
        return difference * monthlyPayment + downpayment;
    }

    getInitialBitcoin(downpayment, startYear, startMonth) {
        if (typeof this.state.prices === 'undefined') {
            return 0;
        }
        let yearMonth = startYear * 12 + startMonth;
        let rate = this.state.prices[yearMonth].price;
        return downpayment/rate;
    }

    getMonthlyBitcoin(monthlyPayment, startYear, startMonth) {
        if (typeof this.state.prices === 'undefined') {
            return 0;
        }
        let startYearMonth = startYear * 12 + (startMonth + 1);
        let yearMonth = this.getCurrentYearMonth();
        let monthPrice = 0;
        for (let ym = startYearMonth;ym <= yearMonth; ym++) {
            let rate = this.state.prices[ym].price;
            monthPrice += monthlyPayment/rate;
        }
        return monthPrice;
    }

    setDownpayment(event) {
        let downpayment = parseInt(event.target.value);
        if (isNaN(downpayment)) {
            downpayment = 0;
        }
        if (downpayment < 0) {
            downpayment = 0;
        }
        if (downpayment > 50000000) {
            downpayment = 50000000;
        }
        this.setState({
            downpayment: downpayment,
            totalPayment: this.getTotalPayment(
                downpayment,
                this.state.monthlyPayment,
                this.state.startYear,
                this.state.startMonth
            ),
            initialBitcoin: this.getInitialBitcoin(
                downpayment,
                this.state.startYear,
                this.state.startMonth
            )
        });
    }

    setMonthlyPayment(event) {
        let monthlyPayment = parseInt(event.target.value);
        if (isNaN(monthlyPayment)) {
            monthlyPayment = 0;
        }
        if (monthlyPayment < 0) {
            monthlyPayment = 0;
        }
        if (monthlyPayment > 5000000) {
            monthlyPayment = 5000000;
        }
        this.setState({
            monthlyPayment: monthlyPayment,
            totalPayment: this.getTotalPayment(
                this.state.downpayment,
                monthlyPayment,
                this.state.startYear,
                this.state.startMonth
            ),
            monthlyBitcoin: this.getMonthlyBitcoin(
                monthlyPayment,
                this.state.startYear,
                this.state.startMonth
            )
        });
    }

    handleYear(event) {
        let startYear = parseInt(event.target.value);
        this.setState({
            startYear: startYear,
            totalPayment: this.getTotalPayment(
                this.state.downpayment,
                this.state.monthlyPayment,
                startYear,
                this.state.startMonth
            ),
            initialBitcoin: this.getInitialBitcoin(
                this.state.downpayment,
                startYear,
                this.state.startMonth
            ),
            monthlyBitcoin: this.getMonthlyBitcoin(
                this.state.monthlyPayment,
                startYear,
                this.state.startMonth
            )
        });
    }

    handleMonth(event) {
        let startMonth = parseInt(event.target.value);
        this.setState({
            startMonth: startMonth,
            totalPayment: this.getTotalPayment(
                this.state.downpayment,
                this.state.monthlyPayment,
                this.state.startYear,
                startMonth
            ),
            initialBitcoin: this.getInitialBitcoin(
                this.state.downpayment,
                this.state.startYear,
                startMonth
            ),
            monthlyBitcoin: this.getMonthlyBitcoin(
                this.state.monthlyPayment,
                this.state.startYear,
                startMonth
            )
        });
    }

    getTotalBitcoin() {
        return this.state.initialBitcoin + this.state.monthlyBitcoin;
    }

    getTotalInvestmentValue() {
        let total = parseInt(this.state.bitcoinPrice * this.getTotalBitcoin());
        if (isNaN(total)) {
            total = 0;
        }
        return total;
    }

    formatDollar(num) {
        var p = num.toFixed(2).split(".");
        return p[0].split("").reverse().reduce(function(acc, num, i, orig) {
            return  num==="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
        }, "");
    }

    getPermaUrl() {
        return "https://www.bitcoinmortgage.info/" +
            this.state.startYear + "/" +
            this.state.startMonth + "/" +
            this.state.downpayment + "/" +
            this.state.monthlyPayment;
    }

    renderMortgage() {
        return (
            <div className="grid-child grid-left">
                <h1>MORTGAGE <img className="icon" src="/icons/house-icon.png" alt="Mortgage"/></h1>
                <form>
                    <label>Start Date:</label>

                    <select value={this.state.startYear} onChange={this.handleYear}>
                        <YearList/>
                    </select>

                    <select value={this.state.startMonth} onChange={this.handleMonth} className="gridright">
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>

                    <label>Down Payment:</label>
                    <input type="text" onChange={this.setDownpayment} value={this.state.downpayment} placeholder="$0"/>

                    <label>Monthly Payment:</label>
                    <input type="text" onChange={this.setMonthlyPayment} value={this.state.monthlyPayment} placeholder="$0"/>

                    <label>Total Spent:</label>
                    <span className="result">${this.formatDollar(this.state.totalPayment)}</span>
                </form>
            </div>
        )
    }

    renderBitcoin() {
        return (
            <div className="grid-child">
                <h1>BITCOIN <img className="icon" src="/icons/bitcoin-icon.png" alt="Bitcoin"/></h1>
                <form>
                    <label>Initial Investment:</label>
                    <span className="number">{parseFloat(this.state.initialBitcoin).toFixed(2)} BTC</span>

                    <label>Monthly Investments:</label>
                    <span className="number">{parseFloat(this.state.monthlyBitcoin).toFixed(2)} BTC</span>

                    <label>&nbsp;</label>
                    <span className="number"/>

                    <label>Total Invested:</label>
                    <span className="number">{parseFloat(this.getTotalBitcoin()).toFixed(2)} BTC</span>

                    <label>Current Value:</label>
                    <span className="result">${this.formatDollar(this.getTotalInvestmentValue())}</span>
                </form>
            </div>
        )
    }

    render() {
        return (
          <div>
              <div className="grid-container">
                {this.renderMortgage()}
                {this.renderBitcoin()}
              </div>
              <CopyToClipboard
                text={this.getPermaUrl()}
                onCopy={() => this.setState({shareText: "Copied to Clipboard!"})}
                >
                 <button>{this.state.shareText}</button>
              </CopyToClipboard>
          </div>
        )
    }
}


function App() {
  let params = useParams();

  return (
    <div className="App">
      <header className="App-header">
        <Mortgage params={params} />
      </header>
    </div>
  );
}

export default App;
